import * as React from "react"
import { LoadingSpinner } from "../components/LoadingSpinner"

interface WithLoadingProps {
  loading: boolean
}

export const withLoading = <P extends object>(Component: React.ComponentType<P>) => {
  return (ownProps: P & WithLoadingProps) => {
    const { loading } = ownProps
    return loading ? <LoadingSpinner /> : <Component {...ownProps as P} />
  }
}
