import styled from "styled-components"

export const AccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const FormError = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  text-align: right;
`
