import React, { useState } from "react"
import { connect } from "react-redux"
import { Formik } from "formik"
import { createUserWithEmailAndPassword } from "../services/firebase"
import { Popup } from "../components/Popup"
import * as T from "../styles/typography"
import { TextInput, Label } from "../styles/forms"
import { Button } from "../styles/buttons"
import { Margin } from "../styles/common"
import { PopupHeader, PopupContent } from "../components/Popup.styles"
import { isJust, emailRegExp, getOrganizationNameFromEmail } from "../utils"
import { FormError } from "./Access.styles"
import { RootState } from "../store/init"
import { withLoading } from "../hocs/withLoading"
import { getCurrentRadarId, getCurrentRadar, isBasicRadarLoading } from "../store/app/selectors"
import { navigate } from "../routers"

interface Values {
  email: string
  password: string
  fullName: string
}

interface StateProps {
  radarId: string
  currentRadar?: Radar
}

export const SignUpPure: React.FC<StateProps> = ({ currentRadar, radarId }) => {
  const [submitStatus, setSubmitStatus] = useState(false)
  const [apiError, setApiError] = useState<string | null>(null)

  const onSubmit = async (values: Values) => {
    const result = await createUserWithEmailAndPassword(values.email, values.password, { fullName: values.fullName })
    setApiError(null)
    if (isJust(result)) {
      setSubmitStatus(true)
    } else {
      setApiError(result.error)
    }
  }

  const validate = (values: Values) => {
    const errors = {} as any

    if (currentRadar) {
      if (!values.email.trim()) {
        errors.email = "Fill missing fields."
      } else if (!currentRadar.basic.organization.includes(getOrganizationNameFromEmail(values.email))) {
        errors.email = "Your email is not in the company domain. Please contact admin."
      } else if (!emailRegExp.test(values.email)) {
        errors.email = "Please correct fields. "
      }

      if (values.password.trim().length < 6) {
        errors.password = "Password should be at least 6 characters"
      }
    }
    return errors
  }

  return (
    <Formik
      initialValues={{ fullName: "", email: "", password: "" }}
      onSubmit={onSubmit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <Popup logo>
          {!submitStatus && (
            <PopupHeader>
              <T.H1>Register to {currentRadar && currentRadar.basic.name}'s Radar!</T.H1>
              <Margin margin={{ top: "5px" }}>
                <T.Body14>
                  Or <T.Prominent to={`/radar/${radarId}/signin`}>Log in to your account</T.Prominent>
                </T.Body14>
              </Margin>
              <Margin margin={{ top: "12px", bottom: "5px" }}>
                <T.Body15>
                  This Radar uses our proprietary machine learning algorithms to create a custom and dynamic map of
                  opportunities.
                </T.Body15>
              </Margin>
            </PopupHeader>
          )}
          {submitStatus && (
            <PopupHeader>
              <T.H1>
                We’ve sent you email confirmation to register. Please go to your email account {values.email} to finish
                registration
              </T.H1>
              <Margin margin={{ top: "35px", bottom: "20px" }}>
                <Button onClick={() => navigate(`/radar/${radarId}/signin`)} wide>
                  OK
                </Button>
              </Margin>
            </PopupHeader>
          )}
          {!submitStatus && (
            <PopupContent>
              <form
                onSubmit={e => {
                  handleSubmit(e)
                  setApiError(null)
                }}
              >
                <div>
                  <Label>Your name (optional)</Label>
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fullName}
                    type="text"
                    name="fullName"
                  />
                </div>
                <div>
                  <Label>Your email</Label>
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    type="text"
                    name="email"
                    hasError={Boolean(errors.email)}
                  />
                </div>
                <div>
                  <Label>Password</Label>
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    type="password"
                    name="password"
                    hasError={Boolean(errors.password)}
                  />
                </div>
                {apiError && (
                  <FormError>
                    <T.Caption bold>{apiError}</T.Caption>
                  </FormError>
                )}
                {errors && (
                  <FormError>
                    <T.Caption bold>{errors.email}</T.Caption>
                  </FormError>
                )}
                {errors && (
                  <FormError>
                    <T.Caption bold>{errors.password}</T.Caption>
                  </FormError>
                )}
                <Margin margin={{ top: "22px", bottom: "20px" }}>
                  <Button type="submit" wide>
                    Register
                  </Button>
                </Margin>
              </form>
            </PopupContent>
          )}
        </Popup>
      )}
    </Formik>
  )
}

export const SignUp = connect<StateProps>((state: RootState) => ({
  radarId: getCurrentRadarId(state),
  currentRadar: getCurrentRadar(state),
  loading: isBasicRadarLoading(state)
}))(withLoading(SignUpPure))
