import styled from "styled-components"
import { FlexInline } from "../utils/styles"

export const SectorMapContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`

export const SidebarColumn = styled(FlexInline)`
  width: ${({ theme }) => theme.sizes.sidebarWidth};
`

export const MapColumn = styled.div`
  overflow: hidden;
  padding: 0 5px 5px 0;
`

export const FAQ = styled.div`
  width: 750px;
  p {
    &:first-child {
      margin-top: 0;
    }
    margin: 34px 0;
  }
`

export const Banner = styled.div`
  background: #0093ee;
  color: white;
  text-align: center;
  padding: 11px 0;
`

export const MapWrapper = styled(FlexInline)`
  overflow: hidden;
`
