import { loop, Cmd } from "redux-loop"

const { API_KEY } = process.env

export type Action =
  | { type: "init"; sectorsApiUrl: string }
  | {
      type: "sectors_fetch_successful"
      sectors: Sector[]
    }
  | { type: "sectors_fetch_failed"; error: Error }

export const init = (sectorsApiUrl: string): Action => ({ type: "init", sectorsApiUrl })

export const fetchSectors = (sectorsApiUrl: string) => async (): Promise<SectorsResponse> => {
  const response = await fetch(`${sectorsApiUrl}?api_key=${API_KEY}`)
  return response.json()
}

export const sectorsFetchSuccessful = (response: SectorsResponse): Action => {
  const { records } = response
  return {
    type: "sectors_fetch_successful",
    sectors: records
  }
}

export const sectorsFetchFailed = (error: Error): Action => ({
  type: "sectors_fetch_failed",
  error
})

export const actions = {
  init
}

export const reducer = (state: SectorsState, action: Action) => {
  switch (action.type) {
    case "init":
      return loop(
        {
          ...state,
          sectorsApiUrl: action.sectorsApiUrl,
          initStarted: true
        },
        Cmd.run(fetchSectors(action.sectorsApiUrl), {
          successActionCreator: sectorsFetchSuccessful,
          failActionCreator: sectorsFetchFailed,
          args: []
        })
      )

    case "sectors_fetch_successful":
      return { ...state, data: action.sectors }
    default:
      return state
  }
}
