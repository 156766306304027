import styled from "styled-components"
import { ModalCloseButton } from "./Modal.styles"

export const Popup = styled.div`
  width: 600px;
  border-radius: ${({ theme }) => theme.sizes.radius * 2}px
  box-shadow: 0 1px 4px 0 rgba(44, 64, 90, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: white;
  position: relative;
`

export const PopupInner = styled.div`
  padding: 42px 60px 22px;
`

export const PopupHeader = styled.div`
  padding: 42px 60px 12px 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`

export const PopupContent = styled.div`
  padding: 12px 60px 22px 60px;
`

export const CloseButton = styled(ModalCloseButton)`
  position: absolute;
  top: 13px;
  right: 13px;
`

export const RadicleMiniLogo = styled.div`
  border: 1px solid black;
  position: absolute;
  border-radius: ${({ theme }) => theme.sizes.radius * 3}px
  box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.2);
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: white;
  top: -32px;
  left: 0;
  right: 0;
  margin: auto;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`
