import styled, { DefaultTheme } from "styled-components"
import { BaseButton } from "../styles/buttons"

const DropdownBase = (theme: DefaultTheme) => `
  padding: 0px 13px;
  min-width: 150px;
  font-size: 14px;
  border: 1px solid ${theme.colors.grey};
  line-height: 1.7;
`

interface DropdownProps {
  open: boolean
}

export const DropdownTrigger = styled(BaseButton)<DropdownProps>`
  ${({ theme }) => DropdownBase(theme)}
  background-color: white;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2;
  cursor: pointer;
  border-radius: ${({ open }) => (open ? "4px 4px 0px 0px" : "4px")};
  border-bottom: ${({ open, theme }) => (open ? "0" : `1px solid ${theme.colors.grey}`)};
  outline: none;
  width: 100%;
`

export const DropdownText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 5px;
`

export const DropdownOptions = styled.div<DropdownProps>`
  ${({ theme }) => DropdownBase(theme)}
  padding-top: 0;
  border-top: ${({ open, theme }) => (open ? "0" : `1px solid ${theme.colors.grey}`)};
  border-radius: ${({ open }) => (open ? "0px 0px 4px 4px" : "4px")};
  position: absolute;
  top: 36px;
  left: 0;
  background: white;
  z-index: ${({ theme }) => theme.zIndexes.dropdownOptions};
  width: 100%;
`

export const DropdownOption = styled.div`
  padding: 6px 0;
  cursor: pointer;
  &:first-child {
    padding-top: 4px;
  }
`
export const DropdownWrapper = styled.div`
  position: relative;
  width: 154px;
`

export const Arrow = styled.div<DropdownProps>`
  width: 10px;
  height: 5px;
  background-image: url(${({ theme }) => theme.icons.arrowDown.url});
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0)")};
`
