import React from "react"
import { SidebarContainer, SidebarHeader, ToggleSidebar, ToggleSidebarButton, ScrollableArea } from "./Sidebar.styles"
import * as Typography from "../styles/typography"
import { Icon } from "../styles/common"

export const Sidebar: React.FC = ({ children }) => {
  const [isOpen, changeVisibility] = React.useState<boolean>(true)

  const triggerVisibility = () => {
    changeVisibility(state => !state)
  }

  return (
    <SidebarContainer open={isOpen}>
      <div>
        {isOpen && (
          <>
            <SidebarHeader>
              <Typography.Tag11>
                filter by
                <br />
                Search Areas
              </Typography.Tag11>
            </SidebarHeader>
            <ScrollableArea>{children}</ScrollableArea>
          </>
        )}
        <ToggleSidebar>
          <ToggleSidebarButton twisted={!isOpen} onClick={triggerVisibility}>
            <Icon name="arrowCircleLeft" />
          </ToggleSidebarButton>
        </ToggleSidebar>
      </div>
    </SidebarContainer>
  )
}
