import * as React from "react"

interface Props {
  render: React.ReactElement
  renderLoading: React.ReactElement
  isLoading: boolean
}

export const Loading: React.FC<Props> = ({ render: Component, renderLoading: LoadingComponent, isLoading }) => {
  if (isLoading) {
    return LoadingComponent
  }
  return Component
}
