import * as React from "react"
import { Spinner, SpinnerElement, SpinnerContainer } from "./LoadingSpinner.styles"

interface Props {
  color?: string
}

export const LoadingSpinner: React.FC<Props> = ({ color }) => {
  return (
    <SpinnerContainer>
      <Spinner>
        <SpinnerElement color={color} />
        <SpinnerElement color={color} />
        <SpinnerElement color={color} />
        <SpinnerElement color={color} />
      </Spinner>
    </SpinnerContainer>
  )
}
