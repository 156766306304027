import styled from "styled-components"
import { Link } from "react-router-dom"

type Align = "left" | "right" | "center"

export const Tag11 = styled.span`
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
`

export const Caption = styled.span<{ bold: boolean }>`
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  line-height: 1.33;
  letter-spacing: 0.3px;
`
interface Typography {
  bold?: boolean
  align?: Align
}

const anchorStyles = `
  a {
    color: inherit;
  }
`

export const Display = styled.h1<Typography>`
  font-size: 34px;
  line-height: 1.29;
  letter-spacing: -0.5px;
  margin: 0;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const H1 = styled.h1<Typography>`
  font-size: 23px;
  line-height: 1.3;
  margin: 0;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  ${anchorStyles}
`

export const H2 = styled.h2<Typography>`
  font-size: 16px;
  margin: 0;
  line-height: 1.38;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  ${anchorStyles}
`

export const H3 = styled.h2<Typography>`
  font-size: 15px;
  line-height: normal;
  margin: 0;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  ${anchorStyles}
`

const bodyStyles = `
  line-height: 1.41;
  margin: 0;
`

export const Body17 = styled.p<Typography>`
  ${bodyStyles}
  ${anchorStyles}
  font-size: 17px;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const Body14 = styled.p<Typography>`
  ${bodyStyles}
  ${anchorStyles}
  font-size: 14px;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const Body15 = styled.p<Typography>`
  ${bodyStyles}
  ${anchorStyles}
  font-size: 15px;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const BodyContent = styled.p<Typography>`
  ${bodyStyles}
  ${anchorStyles}
  font-size: 17px;
  text-align: ${({ align }) => (align ? align : "left")}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const Prominent = styled(Link)`
  && {
    color: ${({ theme }) => theme.colors.info};
    text-decoration: none;
  }
`
