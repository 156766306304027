import React, { useEffect, useState } from "react"
import { theme } from "../styles/themes"

interface Props {
  src?: string
  imageSize?: ImageSize
}

export const Image: React.FC<Props> = ({ src, imageSize }) => {
  const imageRef = React.createRef<HTMLImageElement>()
  const [size, setSize] = useState<ImageSize>()

  useEffect(() => {
    if (imageRef.current && imageSize) {
      const { width } = imageRef.current.getBoundingClientRect()
      const height = (width / imageSize.width) * imageSize.height
      setSize({ width, height })
    }
  }, [])

  return (
    <>
      {imageSize && (
        <img
          src={src ? src : theme.icons.miniLogo.url}
          ref={imageRef}
          width={size ? size.width : imageSize.width}
          height={size ? size.height : imageSize.height}
        />
      )}
      {!imageSize && <img src={src} ref={imageRef} />}
    </>
  )
}
