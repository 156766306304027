import * as React from "react"
import { Columns, Column, ArrowHead } from "./Arrows.styles"
import { Caption } from "../styles/typography"
import { RenderHTML } from "./RenderHTML"
import { Icon } from "../styles/common"

interface Props {
  draggingPosition: Coords
  radarArrows: RadarArrows
}

export const ArrowColumns: React.FC<Props> = ({ draggingPosition, radarArrows }) => {
  return (
    <Columns style={{ transform: `translateX(${draggingPosition.x}px)` }} twoColumns={radarArrows.columns.length === 2}>
      <ArrowHead>
        <Icon name="arrowhead" />
      </ArrowHead>
      {radarArrows.columns.map((column, index) => (
        <Column key={index} width={`${column.size}%`}>
          <Caption bold>
            <RenderHTML html={column.name} />
          </Caption>
        </Column>
      ))}
    </Columns>
  )
}
