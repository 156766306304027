import React, { useState } from "react"
import { Formik } from "formik"
import { Popup } from "../components/Popup"
import * as T from "../styles/typography"
import { TextInput, Label } from "../styles/forms"
import { Button } from "../styles/buttons"
import { Margin } from "../styles/common"
import { PopupHeader, PopupContent } from "../components/Popup.styles"
import { isJust, emailRegExp } from "../utils"
import { FormError } from "./Access.styles"
import { signInWithEmailAndPassword } from "../services/firebase"
import { navigate } from "../routers"
import { connect } from "react-redux"
import { RootState } from "../store/init"
import { getCurrentRadarId, getCurrentRadar, isBasicRadarLoading } from "../store/app/selectors"
import { withLoading } from "../hocs/withLoading"

interface Values {
  email: string
  password: string
}

interface StateProps {
  radarId: string
  currentRadar?: Radar
}

export const SignInPure: React.FC<StateProps> = ({ radarId, currentRadar }) => {
  const [apiError, setApiError] = useState<string | null>(null)

  const onSubmit = async (values: Values) => {
    const result = await signInWithEmailAndPassword(values.email, values.password)
    if (isJust(result)) {
      navigate(`/radar/${radarId}`)
    } else {
      setApiError(result.error)
    }
  }

  const validate = (values: Values) => {
    const errors = {} as any

    if (!values.email.trim()) {
      errors.email = "Fill missing fields."
    } else if (!emailRegExp.test(values.email)) {
      errors.email = "Please correct fields."
    }

    if (values.password.trim().length < 6) {
      errors.password = "Password should be at least 6 characters"
    }
    return errors
  }

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <Popup logo>
          <PopupHeader>
            <T.H1>Login to {currentRadar && currentRadar.basic.name}'s Radar!</T.H1>
            <Margin margin={{ top: "5px" }}>
              <T.Body14>
                Or <T.Prominent to={`/radar/${radarId}/signup`}>Register</T.Prominent>
              </T.Body14>
            </Margin>
            <Margin margin={{ top: "12px", bottom: "5px" }}>
              <T.Body15>
                This Radar uses our proprietary machine learning algorithms to create a custom and dynamic map of
                opportunities.
              </T.Body15>
            </Margin>
          </PopupHeader>
          <PopupContent>
            <form
              onSubmit={e => {
                setApiError(null)
                handleSubmit(e)
              }}
            >
              <Margin margin={{ top: "26px" }}>
                <Label>Your email</Label>
                <TextInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  type="text"
                  name="email"
                  hasError={Boolean(errors.email)}
                />
              </Margin>
              <div>
                <Label>Password</Label>
                <TextInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  type="password"
                  name="password"
                  hasError={Boolean(errors.password)}
                />
              </div>
              {apiError && (
                <FormError>
                  <T.Caption bold>{apiError}</T.Caption>
                </FormError>
              )}
              {errors && (
                <FormError>
                  <T.Caption bold>{errors.email}</T.Caption>
                </FormError>
              )}
              {errors && (
                <FormError>
                  <T.Caption bold>{errors.password}</T.Caption>
                </FormError>
              )}
              <Margin margin={{ top: "22px", bottom: "20px" }}>
                <Button type="submit" wide>
                  Login
                </Button>
              </Margin>
              <T.Body14 align="right">
                <T.Prominent to={`/radar/${radarId}/password_reset`}>Forgot your password?</T.Prominent>
              </T.Body14>
            </form>
          </PopupContent>
        </Popup>
      )}
    </Formik>
  )
}

export const SignIn = connect<StateProps>((state: RootState) => ({
  radarId: getCurrentRadarId(state),
  loading: isBasicRadarLoading(state),
  currentRadar: getCurrentRadar(state)
}))(withLoading(SignInPure))
