import styled, { DefaultTheme, ThemedStyledProps } from "styled-components"

export const backgroundSizeOf = <P>(iconName: string) => {
  return ({ theme }: ThemedStyledProps<P, DefaultTheme>) => {
    const { width, height } = theme.icons[iconName].size
    return `${width}px ${height}px`
  }
}

export const sizeOf = <P>(iconName: string) => {
  return ({ theme }: ThemedStyledProps<P, DefaultTheme>) => {
    const { width, height } = theme.icons[iconName].size
    return `width: ${width}px; height: ${height}px`
  }
}

export const FlexInline = styled.div`
  display: flex;
  align-items: stretch;
`
