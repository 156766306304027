import styled from "styled-components"
import { BaseButton } from "./buttons"

export const NavInline = styled.div`
  display: flex;
  button {
    &:first-child {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`

export const NavButton = styled(BaseButton)`
  width: 45px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.2s linear;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
  &:disabled {
    cursor: default;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    svg {
      opacity: 0.15;
    }
  }
`
