import React from "react"
import { Logo } from "./Logo"
import { HeaderNav, Headline, HeaderTop, RightNav, HeaderContainer } from "./Header.styles"
import { LinkButton } from "../styles/buttons"
import { signOut } from "../services/firebase"

interface Props {
  onFaqOpen: () => void
  radarName: string
}

export const Header: React.FC<Props> = ({ children, onFaqOpen, radarName }) => {
  return (
    <HeaderContainer>
      <HeaderTop>
        <Logo />
        <Headline>
          <strong>Radar</strong> | {radarName}
        </Headline>
        <RightNav>
          <LinkButton onClick={onFaqOpen}>FAQ</LinkButton>
          <span> | </span>
          <LinkButton onClick={() => signOut()}>Logout</LinkButton>
        </RightNav>
      </HeaderTop>
      <HeaderNav>{children}</HeaderNav>
    </HeaderContainer>
  )
}
