import React from "react"
import { Provider } from "react-redux"
import { getStore } from "./store/init"
import ReactDOM from "react-dom"

import { GlobalStyle } from "./styles/global"
import { Routers } from "./routers"
import { ThemeProvider } from "styled-components"
import { theme } from "./styles/themes"
import { AppContextProvider } from "./context/app"
import { Outlets } from "./components/Outlets"

const Index = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={getStore()}>
        <AppContextProvider>
          <Routers />
          <GlobalStyle />
          <Outlets />
        </AppContextProvider>
      </Provider>
    </ThemeProvider>
  )
}

ReactDOM.render(<Index />, document.getElementById("app"))
