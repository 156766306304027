import React from "react"
import { Switch, Route } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"

import { getHistory } from "./store/init"
import { SectorsMap } from "./views/SectorsMap"
import { SignIn } from "./views/SignIn"
import { SignUp } from "./views/SignUp"
import { PasswordReset } from "./views/PasswordReset"
import { Page404 } from "./components/Page404"

export const Routers = () => (
  <ConnectedRouter history={getHistory()}>
    <Switch>
      <Route path="/radar/:id/signup" component={SignUp} exact />
      <Route path="/radar/:id/signin" component={SignIn} exact />
      <Route path="/radar/:id/password_reset" component={PasswordReset} exact />
      <Route path="/radar/:id" component={SectorsMap} exact />
      <Route component={Page404} />
    </Switch>
  </ConnectedRouter>
)

export const navigate = (path: string) => {
  getHistory().push(path)
}

export const redirect = (path: string) => {
  window.location.assign(path)
}
