import styled from "styled-components"

export const Outlet = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(46, 46, 46, 0.85);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndexes.modalOutlet};
`

export const ModalContent = styled.div`
  background: #fff;
  border-radius: ${({ theme }) => theme.sizes.radius * 2}px;
  overflow: hidden;
  min-width: 400px;
  width: 95vw;
  max-height: calc(100vh - 40px);
`

export const ModalHeader = styled.div`
  background: #fafbfc;
  height: 69px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`

export const ModalTitle = styled.div`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
`

export const ModalCloseButton = styled.div`
  font-size: 16px;
  cursor: pointer;
`

export const ModalText = styled.div`
  padding: 30px;
  max-height: 90vh;
  overflow: auto;
`
