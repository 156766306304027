import { RootState } from "../init"
import { createSelector } from "reselect"

export const getCompanies = (state: RootState) => state.companies

export const getSortedCompanies = createSelector(
  [getCompanies],
  ({ companies }) => {
    return Object.keys(companies).reduce((accu, current) => {
      return {
        ...accu,
        [current]: companies[current].sort((a, b) => {
          if (!a.fields.funding) {
            return 1
          }
          if (!b.fields.funding) {
            return -1
          }
          return b.fields.funding - a.fields.funding
        })
      }
    }, {})
  }
)
