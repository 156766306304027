import styled from "styled-components"
import { darken } from "polished"

export const BaseButton = styled.button`
  border-radius: 4px;
  height: 36px;
  outline: none;
  cursor: pointer;
  line-height: 1;
  border: 0;
  font-family: inherit;
`

export const LinkButton = styled(BaseButton)`
  font-size: 14px;
  line-height: 1;
  padding: 4px 8px;
  background: none;
  color: ${({ theme }) => theme.colors.primary};
  height: auto;
`

const filterDisabledColor = "#d4d7da"

export const Filter = styled(BaseButton)<{ off: boolean }>`
  font-size: 14px;
  color: white;
  width: 100%;
  text-align: left;
  margin: 5px 0;
  padding: 10px;
  height: auto;
  background-color: ${({ color, off }) => (off ? filterDisabledColor : color)};
  transition: background-color 0.2s linear;
  &:hover {
    background-color: ${({ color, off }) => darken(0.05, off ? filterDisabledColor : color!)};
  }
`

const getButtonWidth = (wide?: boolean, width?: number) => {
  if (wide) return "100%"
  if (width) return `${width}px`
  return "auto"
}

export const Button = styled(BaseButton)<{ wide?: boolean; width?: number }>`
  background: #0093ee;
  box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
  color: white;
  font-size: 15px;
  height: 42px;
  display: inline-block;
  transition: background 0.1s linear;
  width: ${({ width, wide }) => getButtonWidth(wide, width)}
  &:hover {
    background: #0285d7;
  }
  &:active {
    background: #0079c4;
  }
`
