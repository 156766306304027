import styled from "styled-components"
import { FlexInline } from "../utils/styles"

export const SidebarContainer = styled(FlexInline)<{ open: boolean }>`
  padding: 16px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey};
  position: relative;
  width: ${({ theme, open }) => (open ? theme.sizes.sidebarWidth : 15)}px;
  flex-shrink: 0;
  height: calc(100vh - 145px);
`

export const SidebarHeader = styled.header`
  margin-bottom: 25px;
`

export const ToggleSidebar = styled.div`
  position: absolute;
  top: 12px;
  right: -13px;
  z-index: ${({ theme }) => theme.zIndexes.toggleSidebar};
`

export const ToggleSidebarButton = styled.button<{ twisted: boolean }>`
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transform: rotate(${({ twisted }) => (twisted ? 180 : 0)}deg);
  padding: 0;
`

export const ScrollableArea = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 40px;
`
