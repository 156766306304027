import * as React from "react"
import { connect } from "react-redux"

import { Outlet } from "./Outlet"
import { getCurrentUser } from "../store/user/selectors"
import { Icon, Margin, Center } from "../styles/common"
import { Button } from "../styles/buttons"
import { RootState } from "../store/init"
import { update } from "../services/firebase"
import { normalizeEmial } from "../utils"
import { PopupInner } from "./Popup.styles"
import { Popup } from "./Popup"
import { H1 } from "../styles/typography"

interface Props {
  user: UserState
}

export const NavigationTipModalPure: React.FC<Props> = ({ user }) => {
  const markUserAsVisited = () => {
    update(`users`, `${normalizeEmial(user.email)}`, { ...user, visitBefore: true })
  }

  return (
    <Outlet
      isOpen={false}
      onClose={markUserAsVisited}
      renderContent={({ onClose }) => (
        <Popup onClose={onClose}>
          <PopupInner>
            <Center>
              <Icon name="cursor" />
              <Margin margin={{ top: "31px", bottom: "44px" }}>
                <H1 align="center" bold>
                  Welcome to Radicle’s Radar. You can navigate your Radar by dragging and/or scrolling.
                </H1>
              </Margin>
              <Margin margin={{ bottom: "12px" }}>
                <Button width={190} onClick={markUserAsVisited}>
                  Ok, great!
                </Button>
              </Margin>
            </Center>
          </PopupInner>
        </Popup>
      )}
    />
  )
}

export const NavigationTipModal = connect((state: RootState) => ({
  user: getCurrentUser(state)
}))(NavigationTipModalPure)
