import React, { useEffect, useRef } from "react"
import { DraggableBounds } from "react-draggable"

interface Props {
  onPositionChange: (position: Coords) => void
  draggableBounds: DraggableBounds
}

export const MouseWheelScroll: React.FC<Props> = ({ onPositionChange, children, draggableBounds }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const mousewheelHandler = (e: WheelEvent) => {
      if (e.deltaX !== 0 || e.deltaY !== 0) {
        e.preventDefault()
      }
      onPositionChange({ x: e.deltaX, y: e.deltaY })
    }
    if (ref.current) {
      ref.current.addEventListener("wheel", mousewheelHandler, { passive: false })
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("wheel", mousewheelHandler)
      }
    }
  }, [draggableBounds])

  return <div ref={ref}>{children}</div>
}
