export type Action =
  | { payload: RadarBasic; type: "init radar" }
  | { payload: RadarDetails; type: "init radar details" }
  | { payload: DBRadarsConfig; type: "init radars config" }

const initRadarBasic = (payload: RadarBasic) => ({
  type: "init radar" as "init radar",
  payload
})

const initRadarDetails = (payload: RadarDetails) => ({
  type: "init radar details" as "init radar details",
  payload
})

const initRadarConfig = (payload: DBRadarsConfig) => ({
  type: "init radars config" as "init radars config",
  payload
})

export const actions = {
  initRadarBasic,
  initRadarDetails,
  initRadarConfig
}

export const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case "init radar":
      return {
        ...state,
        radar: {
          ...state.radar,
          basic: action.payload
        }
      }
    case "init radar details":
      return {
        ...state,
        radar: {
          ...state.radar,
          details: action.payload
        }
      }
    case "init radars config":
      return { ...state, config: action.payload }
    default:
      return state
  }
}
