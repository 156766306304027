const sectors: SectorsState = {
  data: [] as Sector[],
  sectorsApiUrl: "",
  initStarted: false
}

const app: AppState = {
  config: { faqText: "" }
}

const companies: CompaniesState = {
  companies: {},
  initStarted: false
}

export const initialState = { sectors, app, companies }
