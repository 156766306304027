import React, { useState, useEffect } from "react"
import * as S from "./Dropdown.styles"

interface State {
  currentIndex: number
  open: boolean
}

interface Props {
  options: string[]
  onChange?: (currentOption: string) => void
}

export const Dropdown: React.FC<Props> = ({ options, onChange }) => {
  const [state, setState] = useState<State>({ currentIndex: 0, open: false })
  const currentOption = options[state.currentIndex]

  useEffect(() => {
    const body = document.querySelector("body")
    if (state.open && body) {
      const handler = ({ target }: Event) => {
        if (!(target as Element).closest(".dropdown")) {
          setState(currentState => ({ ...currentState, open: false }))
        }
      }
      body.addEventListener("click", handler)
      return () => body.removeEventListener("click", handler)
    }
  }, [state.open])

  const handleSelect = (selectedOption: string) => {
    setState(currentState => ({
      ...currentState,
      currentIndex: options.findIndex(option => option === selectedOption),
      open: false
    }))
    if (onChange) {
      onChange(selectedOption)
    }
  }

  const handleTrigger = () => {
    setState(currentState => ({ ...currentState, open: !currentState.open }))
  }

  return (
    <S.DropdownWrapper className="dropdown">
      <S.DropdownTrigger onClick={handleTrigger} open={state.open}>
        <S.DropdownText>{currentOption}</S.DropdownText>
        <S.Arrow open={state.open} />
      </S.DropdownTrigger>
      {state.open && (
        <S.DropdownOptions open={state.open}>
          {options
            .filter(option => option !== currentOption)
            .map(option => (
              <S.DropdownOption onClick={() => handleSelect(option)} key={option}>
                {option}
              </S.DropdownOption>
            ))}
        </S.DropdownOptions>
      )}
    </S.DropdownWrapper>
  )
}
