import React from "react"
import * as M from "./Modal.styles"

export type HandleClose = ({ target, currentTarget }: React.MouseEvent) => void
export interface RenderContentProps {
  onClose: () => void
}

interface Props {
  isOpen: boolean
  onClose: () => void
  renderContent: (props: RenderContentProps) => React.ReactElement
}

export const Outlet: React.FC<Props> = ({ onClose, isOpen, renderContent }) => {
  const handleClose: HandleClose = ({ target, currentTarget }) => {
    if (target === currentTarget) {
      onClose()
    }
  }

  if (isOpen) {
    return (
      <M.Outlet onClick={handleClose} className="modalOutlet">
        {renderContent({ onClose })}
      </M.Outlet>
    )
  }
  return null
}
