import styled from "styled-components"

export const Headline = styled.h2`
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  margin-left: 30px;
`
export const UserAvatar = styled.div`
  background-color: #26c1c9;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 2.8px;
  width: 24px;
  text-align: center;
  line-height: 24px;
`

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 15px 8px;
`

export const RightNav = styled.div`
  margin-left: auto;
  display: flex;
`

export const HeaderNav = styled.div`
  display: flex;
  padding: 15px;
`

export const HeaderContainer = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyLight};
`
