import React, { useState } from "react"
export const RADICLE_EMAIL_COLLECTED = "radicle_demo_email_collected"
export interface AppContext {
  currectSector: string | null
  faqModalOpen: boolean
  contactModalOpen: boolean
  emailCollected: boolean
  setContext: (context: Partial<AppContext>) => void
}

export const initialAppContext = {
  currectSector: null,
  faqModalOpen: false,
  contactModalOpen: false,
  emailCollected: Boolean(window.localStorage.getItem(RADICLE_EMAIL_COLLECTED)) || false,
  setContext: () => {}
}

const AppContext = React.createContext<AppContext>(initialAppContext)

const Provider = AppContext.Provider
export const AppContextConsumer = AppContext.Consumer

interface Props {
  value?: AppContext
}

export const AppContextProvider: React.FC<Props> = ({ children, value }) => {
  const [state, setState] = useState(initialAppContext)
  return (
    <Provider
      value={{
        ...initialAppContext,
        ...value,
        ...state,
        setContext: (newState: any) => setState({ ...state, ...newState })
      }}
    >
      {children}
    </Provider>
  )
}
