import React from "react"
import * as T from "../styles/typography"
import {
  OpportunitySpace,
  OpportunitySpaces,
  OverviewGrid,
  FoundingOverview,
  SectorInformations,
  Tag,
  Tags,
  CompaniesTable,
  CompanyLink,
  ExitColor,
  Source,
  OverviewContainer,
  LeadInvestorsCell
} from "./Overview.styles"
import { Image } from "./Image"
import { Margin } from "../styles/common"

const sectorsDetails: SectorsDetails = {
  fields: [
    {
      key: "incumbents_display",
      name: "Incumbents"
    },
    {
      key: "market_size_display",
      name: "Market size"
    },
    {
      key: "end_consumers",
      name: "Customer type"
    },
    {
      key: "customer_type",
      name: "Customer type"
    },
    {
      key: "product",
      name: "Product"
    },
    {
      key: "business_model_display",
      name: "Business model"
    },
    {
      key: "primary_customer",
      name: "Primary customer"
    },
    {
      key: "geography",
      name: "Geography"
    }
  ]
}

interface Props {
  sector: Sector
  filters: Filter[]
  companies?: Company[]
}

const renderSectorInformationValue = (value: SectorFields[keyof SectorFields]) => {
  if (value && Array.isArray(value)) {
    return value.join(", ")
  }
  return value
}

const renderFoundingOverview = (capital_traction: FoundingGraph[]) => {
  const { thumbnails, url } = capital_traction[0]

  return (
    <FoundingOverview>
      <T.H1>Sector Funding Over Time</T.H1>
      {thumbnails && (
        <Image
          src={url}
          imageSize={{
            width: thumbnails.large.width,
            height: thumbnails.large.height
          }}
        />
      )}
      {!Boolean(thumbnails) && <Image src={url} />}
    </FoundingOverview>
  )
}

export const Overview: React.FC<Props> = ({ sector: { fields }, filters, companies }) => {
  const findOpportunitySpaceColor = (opportunitySpace: string) => {
    const serchedFilter = filters.find(filter => filter.name === opportunitySpace)
    if (serchedFilter) {
      return serchedFilter.color
    }
  }

  return (
    <OverviewContainer>
      <OverviewGrid>
        <div>
          <Margin margin={{ bottom: "16px" }}>
            <T.Display>{fields.sector_name}</T.Display>
          </Margin>
          <T.Body17>{fields.description}</T.Body17>
          <Margin margin={{ bottom: "16px", top: "30px" }}>
            <T.Tag11>Aggregate Venture Capital Funding</T.Tag11>
          </Margin>
          <Margin margin={{ bottom: "30px" }}>
            <T.Display bold>{fields.funding_display}</T.Display>
          </Margin>
        </div>
        <div>
          <T.Tag11>Search Areas</T.Tag11>
          <OpportunitySpaces>
            {fields.opportunity_spaces.map(opportunitySpace => (
              <OpportunitySpace key={opportunitySpace} color={findOpportunitySpaceColor(opportunitySpace)}>
                {opportunitySpace}
              </OpportunitySpace>
            ))}
          </OpportunitySpaces>
          {fields.tags && (
            <Margin margin={{ top: "23px" }}>
              <T.Tag11>Tags</T.Tag11>
              <Tags>
                {fields.tags.map(tag => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </Tags>
            </Margin>
          )}
        </div>
        {fields.capital_traction && renderFoundingOverview(fields.capital_traction)}
        {sectorsDetails && (
          <div>
            <T.H1>Sector Information</T.H1>
            <SectorInformations>
              {sectorsDetails.fields
                .filter(detail => fields[detail.key])
                .map(detail => (
                  <React.Fragment key={detail.name}>
                    <div>
                      <T.Tag11>{detail.name}</T.Tag11>
                    </div>
                    <div>{renderSectorInformationValue(fields[detail.key])}</div>
                  </React.Fragment>
                ))}
            </SectorInformations>
          </div>
        )}
      </OverviewGrid>
      {companies && (
        <Margin margin={{ top: "60px" }}>
          <T.H1>Sector startups</T.H1>
          <CompaniesTable>
            <thead>
              <tr>
                <th>
                  <T.Tag11>COMPANY</T.Tag11>
                </th>
                <th>
                  <T.Tag11>HQ</T.Tag11>
                </th>
                <th>
                  <T.Tag11>FUNDING</T.Tag11>
                </th>
                <th>
                  <T.Tag11>LEAD INVESTORS</T.Tag11>
                </th>
                <th>
                  <T.Tag11>ICS</T.Tag11>
                </th>
                <th>
                  <T.Tag11>PROB. OF EXIT</T.Tag11>
                </th>
              </tr>
            </thead>
            <tbody>
              {companies.map(company => (
                <tr key={company.id}>
                  <th>
                    <CompanyLink href={company.fields.crunchbase_url} target="_blank" rel="noreferrer">
                      <Image src={company.fields.logo_url} imageSize={{ width: 32, height: 32 }} />
                      <T.Body17 bold>{company.fields.company_name}</T.Body17>
                    </CompanyLink>
                  </th>
                  <th>{company.fields.headquarters}</th>
                  <th>{company.fields.funding_display}</th>
                  <LeadInvestorsCell>{company.fields.lead_investors}</LeadInvestorsCell>
                  <th>{company.fields.ics_display}</th>
                  <th>
                    <ExitColor>{company.fields.exit_probability_display}</ExitColor>
                  </th>
                </tr>
              ))}
            </tbody>
          </CompaniesTable>
          <Source>
            <T.Tag11>source: crunchbase</T.Tag11>
          </Source>
        </Margin>
      )}
    </OverviewContainer>
  )
}
