import React from "react"
import * as S from "./Popup.styles"
import { Icon } from "../styles/common"
import { AccessContainer } from "../views/Access.styles"

interface Props {
  onClose?: () => void
  logo?: boolean
}

export const Popup: React.FC<Props> = ({ children, onClose, logo }) => {
  return (
    <AccessContainer>
      <S.Popup>
        {onClose && (
          <S.CloseButton onClick={onClose}>
            <Icon name="close" />
          </S.CloseButton>
        )}
        {logo && (
          <S.RadicleMiniLogo>
            <Icon name="miniLogo" />
          </S.RadicleMiniLogo>
        )}
        {children}
      </S.Popup>
    </AccessContainer>
  )
}
