import styled from "styled-components"
import { backgroundSizeOf, sizeOf } from "../utils/styles"

interface MarginProps {
  margin: {
    top?: string | number
    left?: string | number
    right?: string | number
    bottom?: string | number
  }
}

interface IconProps {
  name: string
}

const defaultMargin = { top: 0, right: 0, left: 0, bottom: 0 }

export const Center = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Margin = styled.div<MarginProps>`
  margin: ${({ margin }) => {
    const { top, left, bottom, right } = { ...defaultMargin, ...margin }
    return `${top} ${right} ${bottom} ${left}`
  }};
`

export const Icon = styled.svg<IconProps>(
  ({ theme, name }) => `
  background-image: url(${theme.icons[name].url});
  background-size: ${backgroundSizeOf<IconProps>(name)({ theme, name })};
  display:block;
  ${sizeOf<IconProps>(name)({ theme, name })}
  `
)
