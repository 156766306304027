import React, { useState } from "react"
import { connect } from "react-redux"
import { Formik, FormikActions } from "formik"
import { Popup } from "../components/Popup"
import * as T from "../styles/typography"
import { TextInput, Label } from "../styles/forms"
import { Button } from "../styles/buttons"
import { Margin } from "../styles/common"
import { PopupHeader, PopupContent } from "../components/Popup.styles"
import { isJust } from "../utils"
import { FormError } from "./Access.styles"
import { passwordReset } from "../services/firebase"
import { navigate } from "../routers"
import { RootState } from "../store/init"
import { getCurrentRadarId } from "../store/app/selectors"

interface Values {
  email: string
}

interface StateProps {
  radarId: string
}

export const PasswordResetPure: React.FC<StateProps> = ({ radarId }) => {
  const [passwordResetStatus, setPasswordResetStatus] = useState(false)

  const onSubmit = async (values: Values, actions: FormikActions<Values>) => {
    const result = await passwordReset(values.email)

    if (isJust(result)) {
      setPasswordResetStatus(true)
    } else {
      actions.setStatus({ error: result.error })
    }
  }

  return (
    <Formik initialValues={{ email: "" }} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, handleBlur, values, status, isSubmitting }) => (
        <Popup logo>
          {!passwordResetStatus && (
            <>
              <PopupHeader>
                <Margin margin={{ bottom: "19px" }}>
                  <T.H1>Reset your password</T.H1>
                </Margin>
              </PopupHeader>
              <PopupContent>
                <form onSubmit={handleSubmit}>
                  <Margin margin={{ top: "26px" }}>
                    <Label>Your email</Label>
                    <TextInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      type="text"
                      name="email"
                    />
                  </Margin>
                  {status && status.error && (
                    <FormError>
                      <T.Caption bold>{status.error}</T.Caption>
                    </FormError>
                  )}
                  <Margin margin={{ top: "22px", bottom: "20px" }}>
                    <Button type="submit" wide disabled={isSubmitting}>
                      Reset
                    </Button>
                  </Margin>
                  <T.Body14 align="right">
                    Or <T.Prominent to={`/radar/${radarId}/signin`}>Log in to your account</T.Prominent>
                  </T.Body14>
                </form>
              </PopupContent>
            </>
          )}
          {passwordResetStatus && (
            <PopupHeader>
              <T.H1>
                We’ve sent you password reset instructions. Please go to your email account {values.email} to reset the
                password.
              </T.H1>
              <Margin margin={{ top: "35px", bottom: "20px" }}>
                <Button wide onClick={() => navigate(`/radar/${radarId}/signin`)}>
                  OK
                </Button>
              </Margin>
            </PopupHeader>
          )}
        </Popup>
      )}
    </Formik>
  )
}

export const PasswordReset = connect<StateProps>((state: RootState) => ({
  radarId: getCurrentRadarId(state)
}))(PasswordResetPure)
