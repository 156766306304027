import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
  colors: {
    grey: "#edeff2",
    primary: "#2c405a",
    greyLight: "#F3F3F3",
    success: "#4ece3d",
    info: "#0093ee",
    danger: "#ff151f"
  },
  transition: 300,
  sizes: {
    sidebarWidth: 175,
    radius: 4,
    arrowColumnsHeight: 60,
    arrrowRowsWidth: 78
  },
  zIndexes: {
    columnsArrow: 1,
    rowsArrow: 2,
    sidebar: 3,
    toggleSidebar: 4,
    dropdownOptions: 5,
    modalOutlet: 6
  },
  icons: {
    arrowDown: {
      url: "/public/arrow-down.svg",
      size: {
        width: 10,
        height: 5
      }
    },
    arrowLeft: {
      url: "/public/arrow-left.svg",
      size: {
        width: 5,
        height: 10
      }
    },
    arrowRight: {
      url: "/public/arrow-right.svg",
      size: {
        width: 5,
        height: 10
      }
    },
    plus: {
      url: "/public/plus.svg",
      size: {
        width: 14,
        height: 14
      }
    },
    minus: {
      url: "/public/minus.svg",
      size: {
        width: 14,
        height: 2
      }
    },
    arrowCircleLeft: {
      url: "/public/arrow-circle-left.svg",
      size: {
        width: 26,
        height: 26
      }
    },
    close: {
      url: "/public/close.svg",
      size: {
        width: 15,
        height: 15
      }
    },
    arrowhead: {
      url: "/public/arrowhead.svg",
      size: {
        width: 9,
        height: 9
      }
    },
    eye: {
      url: "/public/eye.svg",
      size: {
        width: 26,
        height: 26
      }
    },
    lock: {
      url: "/public/lock.svg",
      size: {
        width: 26,
        height: 26
      }
    },
    miniLogo: {
      url: "/public/mini-logo.svg",
      size: {
        width: 36,
        height: 36
      }
    },
    cursor: {
      url: "/public/cursor.svg",
      size: {
        width: 53,
        height: 56
      }
    }
  }
}
