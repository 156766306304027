export type Action = { payload: User; type: "init user data" } | { payload: Partial<User>; type: "update user data" }

const initUserData = (payload: User) => ({
  type: "init user data" as "init user data",
  payload
})

const updateUserData = (payload: Partial<User>) => ({
  type: "update user data" as "update user data",
  payload
})

export const actions = {
  initUserData,
  updateUserData
}

export const reducer = (state: UserState | {}, action: Action) => {
  switch (action.type) {
    case "init user data":
      return action.payload
    case "update user data":
      return { ...state, ...action.payload }
    default:
      return state
  }
}
