import styled from "styled-components"

export const ArrowHead = styled.div`
  position: absolute;
`

export const Row = styled.div<{ height: string }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  padding: 0 7px;
`

export const Rows = styled.div<{ twoColumns: boolean }>`
  border-right: 1px solid ${({ theme }) => theme.colors.grey};
  width: ${({ theme }) => theme.sizes.arrrowRowsWidth}px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  z-index: ${({ theme }) => theme.zIndexes.rowsArrow}
  position: relative;
  background: white;

  ${ArrowHead} {
    bottom: 0px;
    right: -5px;
    transform: rotate(90deg);
  }

  ${Row}:last-child {
    border-bottom: 0;
    ${({ twoColumns }) => (twoColumns ? "flex: 1; align-items: flex-end; padding-bottom: 20px" : "")}
  }

  div:nth-child(2) {
    ${({ twoColumns }) => (twoColumns ? "align-items: flex-start; margin-top: 90px" : "")}
  }

  ${Row} {
    ${({ twoColumns }) => (twoColumns ? "border: 0" : "")}
  }
`

export const Column = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: ${({ theme }) => theme.sizes.arrowColumnsHeight}px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey};
  display: flex
  align-items: center;
  padding: 0 27px;
`

export const Columns = styled.div<{ twoColumns: boolean }>`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  position: relative;
  background: white;
  z-index: ${({ theme }) => theme.zIndexes.columnsArrow};

  ${ArrowHead} {
    bottom: -5px;
    right: 0;
  }
  ${Column}:last-child {
    border-right: 0;
    ${({ twoColumns }) => (twoColumns ? "text-align: right; flex: 1; justify-content: flex-end;" : "")}
  }
  ${Column} {
    ${({ twoColumns }) => (twoColumns ? "border: 0" : "")}
  }
`
