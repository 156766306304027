import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i');

  body, html {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    color: ${({ theme }) => theme.colors.primary};
  }

  * {
    box-sizing: border-box;
  }

  .hex-text {
    color: white;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hex-text p, .hex-text strong {
    margin: 2.5px 0;
  }

  .hex-group {
    cursor: pointer;
  }

  .react-draggable {
    cursor: move;
  }

  .hex-map {
    transition: height ${({ theme }) => theme.transition / 1000}s linear, width ${({ theme }) =>
  theme.transition / 1000}s linear;
  }
`
