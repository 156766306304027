import styled from "styled-components"

export const TextInput = styled.input<{ hasError?: boolean }>`
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.danger : theme.colors.grey)}
  border-radius: ${({ theme }) => theme.sizes.radius}px
  font-size: 14px;
  padding: 11px 15px;
  width: 100%;
  margin-bottom: 8px;
  color: inherit;
  outline: none;
`

export const Label = styled.label`
  font-size: 12px
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  display: block;
`
