import "firebase/auth"
import "firebase/database"
import firebase from "firebase/app"
import { normalizeEmial, Just, Nothing, getOrganizationNameFromEmail } from "../utils"
import { getStore } from "../store/init"
import { getCurrentRadar } from "../store/app/selectors"

export const defaultConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
}

export type FirebaseDb = {
  database: firebase.database.Database
  auth: firebase.auth.Auth
}

let _db: FirebaseDb
export type FirebaseConfig = typeof defaultConfig
export const getFirebaseDb = (config: FirebaseConfig = defaultConfig): FirebaseDb => {
  if (!_db) {
    const app = firebase.initializeApp(config)
    _db = { database: app.database(), auth: firebase.auth() }
  }
  return _db
}

const getErrorMessages = (code: string, message: string) => {
  const errorsMessages = {
    "auth/email-already-in-use": "Your email is already registered. Please log in to continue.",
    "auth/wrong-password": "Your email or password are incorrect",
    "auth/user-not-found": "Your email or password are incorrect"
  } as any

  return errorsMessages[code] ? errorsMessages[code] : message
}

const isUserBelongsToOrganization = (organization: string, userEmail: string) => {
  return organization.includes(getOrganizationNameFromEmail(userEmail))
}

export const createUserWithEmailAndPassword = async (email: string, password: string, profile: UserProfile) => {
  try {
    const { user } = await _db.auth.createUserWithEmailAndPassword(email, password)
    if (user) {
      add("users", normalizeEmial(user.email!), {
        uid: user.uid,
        email: user.email,
        fullName: profile.fullName
      })

      if (_db.auth.currentUser) {
        _db.auth.currentUser.sendEmailVerification()
      }

      return Just<firebase.User>(user)
    }

    return Nothing("")
  } catch (ex) {
    return Nothing(getErrorMessages(ex.code, ex.message))
  }
}

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const user = await _db.auth.signInWithEmailAndPassword(email, password)
    const dbUser: User = (await getRef(`users/${normalizeEmial(email)}`).once("value")).val()
    const store = getStore()
    const currentRadar = getCurrentRadar(store.getState())

    if (_db.auth.currentUser!.emailVerified) {
      if (
        currentRadar &&
        !dbUser.isAdmin &&
        !isUserBelongsToOrganization(currentRadar.basic.organization, dbUser.email)
      ) {
        return Nothing("Your email is not in the company domain. Please contact admin.")
      }
      return Just<firebase.auth.UserCredential>(user)
    } else {
      await _db.auth.signOut()
      return Nothing("Your email address has not been verified")
    }
  } catch (ex) {
    return Nothing(getErrorMessages(ex.code, ex.message))
  }
}

export const signOut = () => _db.auth.signOut()

export const passwordReset = async (email: string) => {
  try {
    await _db.auth.sendPasswordResetEmail(email)
    return Just<boolean>(true)
  } catch (ex) {
    return Nothing(ex.message)
  }
}

export const passwordUpdate = (password: string) => {
  const { currentUser } = _db.auth
  if (currentUser) {
    currentUser.updatePassword(password)
  }
}

const refs: SMap<firebase.database.Reference> = {}
const getRef = (path: string) => {
  const p = path
  if (!refs[p]) {
    refs[p] = getFirebaseDb().database.ref(p)
  }
  return refs[p]
}

export const push = <T2 extends keyof DBSchema>(child: T2, item: any) => getRef(child).push(item)

export const add = <T2 extends keyof DBSchema>(child: T2, id: string, item: any) =>
  getRef(child)
    .child(id)
    .set(item)

export const update = <T2 extends keyof DBSchema>(child: T2, id: string, item: any) =>
  getRef(child)
    .child(id)
    .update(item)

export type EventType = firebase.database.EventType

interface SubscribeParams<O> {
  onInit?: (values: O) => void
  onChange?: (type: EventType, value: any) => void
}

const subscribeOn: EventType[] = ["child_changed", "child_added", "child_removed", "value"]

export async function subscribe<T>(path: string, { onInit, onChange }: SubscribeParams<T>) {
  const collectionRef = getRef(path)
  if (onInit) onInit((await collectionRef.once("value")).val())
  if (onChange)
    subscribeOn.forEach(type => collectionRef.limitToLast(1).on(type, (s: any) => onChange(type, s ? s.val() : null)))
}

export const updateCollection = <T>(dict: T[], type: EventType, value: T, key: keyof T) => {
  if (type === "child_added") return [...dict, value]
  if (type === "child_removed") return dict.filter(it => it[key] !== value[key])
  if (type === "child_changed") return dict.map(it => (it[key] === value[key] ? value : it))
  return dict
}
