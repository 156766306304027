import React, { useEffect } from "react"
import { DraggableBounds as DraggableBoundsType } from "react-draggable"

import { calcDraggableBounds } from "../utils/hexMap"

interface Props {
  onBoundsChange: (bounds: DraggableBoundsType) => void
}

export const DraggableBounds: React.FC<Props> = ({ children, onBoundsChange }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    const updateDraggableBounds = () => {
      onBoundsChange(calcDraggableBounds())
    }

    window.addEventListener("resize", updateDraggableBounds)

    if (ref.current) {
      ref.current.addEventListener("transitionstart", updateDraggableBounds)
      ref.current.addEventListener("transitionend", updateDraggableBounds)
    }

    return () => {
      window.removeEventListener("resize", updateDraggableBounds)
      if (ref.current) {
        ref.current.removeEventListener("transitionstart", updateDraggableBounds)
        ref.current.removeEventListener("transitionend", updateDraggableBounds)
      }
    }
  }, [ref])

  return <div ref={ref}>{children}</div>
}
