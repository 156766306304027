export const Just = <T>(value: T): Just<T> => ({ type: "Just", value })
export const Nothing = (error: string): Nothing => ({ type: "Nothing", error })

export const isJust = <T>(v: any): v is Just<T> => v.type === "Just"
export const isNothing = (v: any): v is Nothing => v.type === "Nothing"

export const range = (from: number, to: number) => Array.from(new Array(to), (_, i) => i + from)

export const toArray = <T, T2>(map: SMap<T>, toValue: (t: T, key: keyof T, index: number) => T2) => {
  const result: T2[] = []
  Object.keys(map || {}).forEach((field, index) => result.push(toValue(map[field], field as keyof T, index)))
  return result
}

export const toOption = ({ name, id }: Named): Option => ({ label: name, value: id })

export const positiveOrZero = (num: number) => (num > 0 ? num : 0)

export const normalizeEmial = (email: string) => email.replace(/\./g, "==")
export const parseEmail = (emailId: string) => emailId.replace(/\==/g, ".")

export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i

export const getOrganizationNameFromEmail = (email: string) => email.split("@")[1]
