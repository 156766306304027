import * as d3 from "d3"
import { range, positiveOrZero } from "."
import { theme } from "../styles/themes"

const polygonRadius = 45
const hex = [polygonRadius, 6, Math.PI / 2]

interface EmptyCell {
  fields: {
    column: number
    row: number
  }
  empty: true
}

interface HexConfig {
  disabled: boolean
  transparent: boolean
}

type SectorCell = Sector & {
  empty: false
}

interface GridDataConfigOptions {
  currentTag: string
  filtersState: FiltersState
}

type GridData = SectorCell | EmptyCell
type GridDataWithConfig = SectorCell & HexConfig | EmptyCell & HexConfig

type Vector = [number, number]
type Vectors = Vector[]

type OnClick = (id: string) => void

export const getPolygonVectors = (radius: number, nSides: number, startAngle: number): Vectors => {
  return d3.range(nSides).map(
    (side): Vector => {
      const angle = startAngle + (2 * Math.PI * side) / nSides
      return [Math.round(radius * Math.cos(angle)), Math.round(radius * Math.sin(angle))]
    }
  )
}

export const getPolygonEdges = (vectors: Vectors) => {
  return vectors.map(vector => Math.abs(vector[0] + vector[1]))
}

export const getPolygonPath = (vectors: Vectors) => {
  let d = ""
  vectors.forEach(pt => {
    d += (d.length ? "L" : "M") + pt.join(",")
  })

  return d + "Z"
}

const hexVectors = getPolygonVectors.apply(null, hex)
const hexPath = getPolygonPath(hexVectors)
const longestEdge = Math.max.apply(null, getPolygonEdges(hexVectors))

export const findBoundaries = (sectors: Sector[]): Boundaries => {
  return sectors.reduce(
    (accu, { fields }) => ({
      ...accu,
      rows: fields.row > accu.rows ? fields.row : accu.rows,
      columns: fields.column > accu.columns ? fields.column : accu.columns
    }),
    {
      columns: 0,
      rows: 0
    }
  )
}

const isTransparent = (currentTag: string, tags?: string[]) => {
  if (currentTag !== "All Tags") {
    return tags ? !tags.includes(currentTag) : true
  }
  return false
}

export const getDataGridWithConfig = (gridData: GridData[], { currentTag, filtersState }: GridDataConfigOptions) => {
  return gridData.map(data => {
    if (!data.empty) {
      return {
        ...data,
        transparent: isTransparent(currentTag, data.fields.tags),
        disabled: data.fields.opportunity_spaces.some(space => filtersState[space])
      }
    }
    return { ...data, transparent: false, disabled: false }
  })
}

let _hexGroup: d3.Selection<SVGElement, GridDataWithConfig, null, undefined> | null = null

export const containerSize = (columns: number, rows: number) => ({
  width: columns * polygonRadius * 1.65 + polygonRadius / 2,
  height: rows * polygonRadius * 1.9 + polygonRadius
})

export const attachOnClick = (onClick: OnClick) => {
  if (_hexGroup) {
    _hexGroup
      .selectAll(".hex-group")
      .filter((data: GridData) => !data.empty)
      .on("click", (data: Sector) => onClick(data.id))
  }
}

export const renderVisiblityIndicators = (
  group: d3.Selection<SVGGElement, GridData, SVGGElement, GridDataWithConfig>
) => {
  const { icons } = theme
  return group
    .filter(data => !data.empty)
    .append("svg:image")
    .attr("xlink:href", (data: SectorCell) => (Boolean(data.fields.sector_completed) ? icons.eye.url : icons.lock.url))
    .attr("x", icons.eye.size.width / -2)
    .attr("y", icons.eye.size.height * -2)
    .attr("width", icons.eye.size.width)
    .attr("height", icons.eye.size.height)
}

export const drawMap = (svg: SVGSVGElement, gridData: GridData[], indicatorsVisible: boolean) => {
  _hexGroup = d3.select(svg)
  const hexGroups = _hexGroup
    .append("g")
    .attr("class", "hexes")
    .attr("transform", "translate(8, 8)")
    .selectAll(".hex")
    .data(gridData)
    .enter()

  const group = hexGroups
    .append("g")
    .attr("transform", data => {
      const cx = (data.fields.column - 1) * polygonRadius * 1.65 + polygonRadius
      const cy =
        (data.fields.row - 1) * polygonRadius * 1.9 + (data.fields.column % 2 ? polygonRadius : polygonRadius * 2)
      return `translate(${cx}, ${cy})`
    })
    .attr("class", data => (!data.empty ? "hex-group" : ""))

  group
    .append("path")
    .attr("class", "hex")
    .attr("d", hexPath)
    .attr("fill", data => {
      return data.empty ? theme.colors.greyLight : data.fields.color_codes[0]
    })
    .attr("transform", "rotate(90 0 0)")

  if (indicatorsVisible) {
    renderVisiblityIndicators(group)
  }

  const hexText = group
    .append("foreignObject")
    .attr("width", longestEdge)
    .attr("height", longestEdge)
    .attr("transform", `translate(-${longestEdge / 2}, -${longestEdge / 2})`)
    .append("xhtml:div")
    .attr("style", "font-size: 9px")
    .attr("class", "hex-text")

  hexText.append("strong").text(data => (!data.empty ? data.fields.sector_name : ""))
}

export const updateMap = (gridData: GridDataWithConfig[]) => {
  if (_hexGroup) {
    _hexGroup
      .selectAll(".hex")
      .data(gridData)
      .transition()
      .duration(theme.transition)
      .attr("fill", data => {
        return data.empty || data.disabled ? theme.colors.greyLight : data.fields.color_codes[0]
      })
      .attr("opacity", data => {
        return data.transparent ? 0.3 : 1
      })
  }
}

export const zoomInMap = (zoom: number, gridData: GridDataWithConfig[]) => {
  if (_hexGroup) {
    const hexText = _hexGroup.selectAll(".hex-text").data(gridData)

    _hexGroup
      .selectAll(".hexes")
      .transition()
      .duration(theme.transition)
      .attr("transform", () => `scale(${zoom}), translate(8, 8)`)
      .attr("transform-origin", () => "left top")

    hexText.attr("style", "font-size: 7px")
    hexText.append("p").text(data => (!data.empty ? `(${data.fields.funding_display})` : ""))
    hexText.append("p").text(data => (!data.empty ? data.fields.company_hex_display : ""))
  }
}

export const zoomOutMap = (zoom: number) => {
  if (_hexGroup) {
    const hexText = _hexGroup.selectAll(".hex-text")
    _hexGroup
      .selectAll(".hexes")
      .transition()
      .duration(theme.transition)
      .attr("transform", () => `scale(${zoom}), translate(8, 8)`)
      .attr("transform-origin", () => "left top")

    hexText.attr("style", "font-size: 9px")
    hexText.selectAll("p").remove()
  }
}

export const generateGridData = (boundaries: Boundaries, sectors: Sector[]): GridData[] => {
  const grid = range(0, boundaries.columns).reduce((accu, column) => {
    const rows = range(0, boundaries.rows).map((_, index) => ({ column: column + 1, row: index + 1 }))
    return [...accu, ...rows]
  }, [])

  return grid.map(cell => {
    const sector = sectors.find(
      ({ fields }) => cell.row === fields.row && cell.column === fields.column && Boolean(fields.to_include)
    )
    return sector ? { ...sector, empty: false as false } : { fields: cell, empty: true as true }
  })
}

export const calcDraggableBounds = () => {
  const hexMap = document.querySelector(".hex-map")
  const hexMapContainer = document.querySelector(".hex-map-container")
  const body = document.body

  if (hexMap && body && hexMapContainer) {
    const { height: haxMapHeight, width: hexMapWidth } = hexMap.getBoundingClientRect()
    const { left, top } = hexMapContainer.getBoundingClientRect()
    const { height, width } = body.getBoundingClientRect()
    const { arrowColumnsHeight, arrrowRowsWidth } = theme.sizes

    return {
      top: positiveOrZero(haxMapHeight + top + arrowColumnsHeight + 20 - height) * -1,
      bottom: 0,
      left: positiveOrZero(hexMapWidth + left + arrrowRowsWidth + 20 - width) * -1,
      right: 0
    }
  }

  return {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}
