import { loop, Cmd } from "redux-loop"

const { API_KEY } = process.env

export type Action =
  | { type: "init companies"; companyApiUrl: string; companyIds: string[]; sectorId: string }
  | {
      type: "companies_fetch_successful"
      sectorId: string
      companies: Company[]
    }
  | { type: "companies_fetch_failed"; error: Error }

export const init = (companyApiUrl: string, companyIds: string[], sectorId: string): Action => ({
  type: "init companies",
  companyApiUrl,
  companyIds,
  sectorId
})

export const companiesFetchSuccessful = (companies: Company[], sectorId: string): Action => {
  return {
    type: "companies_fetch_successful",
    companies,
    sectorId
  }
}

export const companiesFetchFailed = (error: Error): Action => ({
  type: "companies_fetch_failed",
  error
})

export const fetchCompanies = async (companyApiUrl: string, companyIds: string[]) => {
  const companyResponses = await Promise.all(
    companyIds.map(id => {
      return fetch(`${companyApiUrl}/${id}?api_key=${API_KEY}`)
    })
  )
  return Promise.all(companyResponses.map(response => response.json()))
}

export const actions = {
  init
}

export const reducer = (state: CompaniesState, action: Action) => {
  switch (action.type) {
    case "init companies":
      return loop(
        {
          ...state,
          initStarted: true,
          companies: state.companies
        },
        Cmd.run(fetchCompanies, {
          successActionCreator: (companies: Company[]) => companiesFetchSuccessful(companies, action.sectorId),
          failActionCreator: companiesFetchFailed,
          args: [action.companyApiUrl, action.companyIds]
        })
      )

    case "companies_fetch_successful":
      return {
        ...state,
        companies: { ...state.companies, [action.sectorId]: action.companies }
      }

    default:
      return state
  }
}
