import styled from "styled-components"

export const OverviewGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 50px;
`

export const OpportunitySpace = styled.div<{ color?: string }>`
  border-radius: 4px;
  height: 36px;
  font-size: 14px;
  color: white;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: ${({ color }) => color};
`

export const OpportunitySpaces = styled.div`
  display: flex;
  margin-top: 18px;
`

export const FoundingOverview = styled.div`
  img {
    margin-top: 20px;
    width: 100%;
  }
`

export const LeadInvestorsCell = styled.th`
  width: 27%;
`

export const SectorInformations = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px 20px;
  margin-top: 25px;
`

export const Tags = styled.div`
  display: flex;
  margin-top: 15px;
`

export const Tag = styled.div`
  margin: 0 2.5px;
  line-height: 24px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`

export const CompaniesTable = styled.table`
  width: 100%;
  margin-top: 20px;
  th:last-child {
    text-align: right;
  }
  th:last-child,
  tbody:last-child {
    padding-right: 0;
  }
  th:first-child,
  tbody:first-child {
    padding-left: 0;
  }
  tbody th {
    padding: 23px 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  }
  th {
    text-align: left;
    font-weight: normal;
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const CompanyLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  img {
    margin-right: 11px;
  }
`

export const ExitColor = styled.span<{ isPositive?: boolean }>`
  color: ${({ isPositive, theme }) => (isPositive ? theme.colors.success : theme.colors.primary)};
`

export const Source = styled.div`
  margin: 40px 0px 50px 0;
  text-align: right;
`

export const OverviewContainer = styled.div`
  padding-left: 5px;
  padding-right: 11px;
`
