import { RootState } from "../init"
import { createSelector } from "reselect"
import { toArray } from "../../utils"

export const getRadarsConfig = (state: RootState) => {
  return state.app.config
}

export const getCurrentRadarId = (state: RootState) => {
  return state.router.location.pathname.split("/")[2]
}

export const getAuthPageName = (state: RootState) => {
  return state.router.location.pathname.split("/")[3]
}

export const isAuthPage = (state: RootState) => {
  return ["signup", "signin", "password_reset"].includes(getAuthPageName(state))
}

export const getCurrentRadar = (state: RootState) => state.app.radar

export const getFilters = createSelector(
  [getCurrentRadar],
  (radar: DBRadar) => {
    if (radar && radar.details) {
      return toArray<string, Filter>(radar.details.filters, (value, key) => ({
        name: key.toString(),
        color: value
      }))
    }
    return []
  }
)

export const isRadarLoding = (state: RootState) => {
  return !Boolean(state.app.radar && state.app.radar.basic && state.app.radar.details)
}

export const isBasicRadarLoading = (state: RootState) => {
  return !Boolean(state.app.radar && state.app.radar.basic)
}
