import React, { createRef, useEffect } from "react"

import {
  findBoundaries,
  generateGridData,
  drawMap,
  getDataGridWithConfig,
  updateMap,
  zoomInMap,
  zoomOutMap,
  containerSize,
  attachOnClick
} from "../utils/hexMap"

interface Props {
  sectors: Sector[]
  currentTag: string
  filtersState: FiltersState
  zoom: number
  onClick: (id: string) => void
  dragging: boolean
  mapSize?: Boundaries
  indicatorsVisible: boolean
}

export const HexMap: React.FC<Props> = ({
  sectors,
  currentTag,
  filtersState,
  zoom,
  onClick,
  dragging,
  mapSize,
  indicatorsVisible
}) => {
  const map = createRef<SVGSVGElement>()
  const boundaries = mapSize || findBoundaries(sectors)
  const { width, height } = containerSize(boundaries.columns, boundaries.rows)

  useEffect(() => {
    const gridData = generateGridData(boundaries, sectors)
    if (map.current) {
      drawMap(map.current, gridData, indicatorsVisible)
    }
  }, [map.current, sectors])

  useEffect(() => {
    if (map.current) {
      attachOnClick(onClick)
    }
  }, [map.current, sectors, dragging])

  useEffect(() => {
    const gridData = getDataGridWithConfig(generateGridData(boundaries, sectors), { currentTag, filtersState })
    if (map.current) {
      updateMap(gridData)
    }
  }, [map.current, sectors, filtersState, currentTag])

  useEffect(() => {
    const gridData = getDataGridWithConfig(generateGridData(boundaries, sectors), { currentTag, filtersState })
    if (zoom === 1) {
      zoomOutMap(zoom)
    } else {
      zoomInMap(zoom, gridData)
    }
  }, [zoom])

  return <svg width={width * zoom} height={height * zoom} ref={map} className="hex-map" />
}
