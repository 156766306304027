import React, { useState } from "react"
import { add } from "../services/firebase"
import { normalizeEmial, emailRegExp } from "../utils"
import { Formik, FormikErrors } from "formik"

interface Values {
  email: string
}

interface Props {
  onSuccess?: () => void
  children: (props: { errors: FormikErrors<Values>; submitState: string | null }) => React.ReactElement
  source?: string
  target?: "maillist" | "maillist2"
}

export const EmailSubscriberForm: React.FC<Props> = ({
  onSuccess,
  children,
  target = "maillist",
  source = "landing"
}) => {
  const [submitState, setSubmitState] = useState<string | null>(null)
  const onSubmit = async (values: Values) => {
    setSubmitState(null)
    try {
      await add(target, normalizeEmial(values.email), {
        createdAt: new Date().toString(),
        email: values.email,
        newsletter: true,
        source
      })
      if (onSuccess) {
        onSuccess()
      }
      setSubmitState("success")
    } catch (error) {
      setSubmitState("error")
    }
  }

  const validate = (values: Values) => {
    const errors = {} as any
    if (!emailRegExp.test(values.email) || !values.email.trim()) {
      errors.email = "Incorrect email"
    }
    return errors
  }

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors }) => children({ errors, submitState })}
    </Formik>
  )
}
