import * as React from "react"
import { connect } from "react-redux"
import { Outlet } from "./Outlet"
import { BodyContent, H1, Body15, Caption, Body14 } from "../styles/typography"
import { Modal } from "./Modal"
import { FAQ } from "../views/SectorsMap.styles"
import { RenderHTML } from "./RenderHTML"
import { Popup } from "./Popup"
import { AppContextConsumer, RADICLE_EMAIL_COLLECTED } from "../context/app"
import { getRadarsConfig, getCurrentRadar } from "../store/app/selectors"
import { RootState } from "../store/init"
import { PopupInner } from "./Popup.styles"
import { EmailSubscriberForm } from "./EmailSubscriberForm"
import { Form, Field, FieldProps } from "formik"
import { Button } from "../styles/buttons"
import { TextInput } from "../styles/forms"
import { FormError } from "../views/Access.styles"

interface Props {
  config: DBRadarsConfig
  radar: DBRadar
}

export const OutletsPure: React.FC<Props> = ({ config, radar }) => {
  return (
    <AppContextConsumer>
      {({ faqModalOpen, setContext, contactModalOpen, emailCollected }) => (
        <>
          <Outlet
            isOpen={faqModalOpen}
            onClose={() => setContext({ faqModalOpen: false })}
            renderContent={({ onClose }) => (
              <Modal title="FAQ" onClose={onClose}>
                <BodyContent>
                  <FAQ>
                    <RenderHTML html={config.faqText} />
                  </FAQ>
                </BodyContent>
              </Modal>
            )}
          />

          <Outlet
            isOpen={contactModalOpen}
            onClose={() => setContext({ contactModalOpen: false })}
            renderContent={({ onClose }) => (
              <Popup onClose={onClose} logo>
                <PopupInner>
                  <H1>
                    This is a sample radar. If you wish to view the full content of this radar, please contact{" "}
                    <a href="mailto:stu@rad.report">stu@rad.report</a>
                  </H1>
                </PopupInner>
              </Popup>
            )}
          />

          {radar && (
            <Outlet
              isOpen={radar.basic.demo && !emailCollected}
              onClose={() => {
                window.localStorage.setItem(RADICLE_EMAIL_COLLECTED, "true")
                setContext({ emailCollected: true })
              }}
              renderContent={({ onClose }) => (
                <Popup logo>
                  <PopupInner>
                    <EmailSubscriberForm target="maillist2" onSuccess={onClose} source={radar.basic.name}>
                      {({ errors, submitState }) => (
                        <Form>
                          <Body15>To access this radar, you have to provide your email address</Body15>
                          <br />
                          <Field
                            name="email"
                            render={(p: FieldProps) => <TextInput {...p.field} placeholder="Your email" />}
                          />
                          {(errors.email || submitState === "error") && (
                            <FormError>
                              <Caption bold>{errors.email}</Caption>
                            </FormError>
                          )}
                          <br />
                          <br />
                          <Body14>
                            By submitting, you agree to our <a href="/terms">T&C</a>
                          </Body14>
                          <br />
                          <Button type="submit" wide>
                            Submit
                          </Button>
                        </Form>
                      )}
                    </EmailSubscriberForm>
                  </PopupInner>
                </Popup>
              )}
            />
          )}
        </>
      )}
    </AppContextConsumer>
  )
}

export const Outlets = connect((state: RootState) => ({
  config: getRadarsConfig(state),
  radar: getCurrentRadar(state)
}))(OutletsPure)
