import React from "react"
import { ModalContent, ModalHeader, ModalCloseButton, ModalText } from "./Modal.styles"
import { Caption } from "../styles/typography"
import { Icon } from "../styles/common"

interface Props {
  title: string
  onClose: () => void
}

export const Modal: React.FC<Props> = ({ children, title, onClose }) => {
  return (
    <ModalContent>
      <ModalHeader>
        <Caption bold>{title}</Caption>
        <ModalCloseButton onClick={onClose}>
          <Icon name="close" />
        </ModalCloseButton>
      </ModalHeader>
      <ModalText>{children}</ModalText>
    </ModalContent>
  )
}
