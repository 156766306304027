import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Draggable, { DraggableData, DraggableBounds as DraggableBoundsType } from "react-draggable"

import { init as initSectorsAction } from "../store/sectors/reducer"
import { init as initCompaniesAction } from "../store/companies/reducer"
import { RootState } from "../store/init"
import { Sidebar } from "../components/Sidebar"
import { MapColumn, Banner, SectorMapContainer, MapWrapper } from "./SectorsMap.styles"
import { getSelectorsState } from "../store/sectors/selectors"
import * as selectors from "../store/app/selectors"
import { Header } from "../components/Header"
import { Filter } from "../styles/buttons"
import { Dropdown } from "../components/Dropdown"
import { RightNav } from "../components/Header.styles"
import { Margin, Icon } from "../styles/common"
import { NavInline, NavButton } from "../styles/navigations"
import { HexMap } from "../components/HexMap"
import { H2 } from "../styles/typography"
import { calcDraggableBounds } from "../utils/hexMap"
import { ArrowRows } from "../components/ArrowRows"
import { ArrowColumns } from "../components/ArrowColumns"
import { withLoading } from "../hocs/withLoading"
import { FlexInline } from "../utils/styles"
import { MouseWheelScroll } from "../components/MouseWheelScroll"
import { DraggableBounds } from "../components/DraggableBounds"
import { AppContextConsumer } from "../context/app"
import { Outlet } from "../components/Outlet"
import { Modal } from "../components/Modal"
import { Overview } from "../components/Overview"
import { getSortedCompanies } from "../store/companies/selectors"
import { Loading } from "../components/Loading"
import { LoadingSpinner } from "../components/LoadingSpinner"
import { NavigationTipModal } from "../components/NavigationTipModal"

type Actions = {
  initSectors: typeof initSectorsAction
  initCompanies: typeof initCompaniesAction
}

interface Props {
  sectors: Sector[]
  sectorsApiUrl?: string
  filters: Filter[]
  config: DBRadarsConfig
  companiesMap: SMap<Company[] | undefined>
  radar: DBRadar
}

export const SectorsMapPlain: React.FC<Actions & Props> = ({
  initSectors,
  sectors,
  filters,
  radar: {
    basic: { name: radarName, demo },
    details: { companiesApiUrl, mapSize, tags, sectorsApiUrl, arrows: radarArrows }
  },
  companiesMap,
  initCompanies
}) => {
  const [filtersState, changeFiltersDisablity] = useState<FiltersState>({})
  const [currentTag, changeCurrentTag] = useState<string>("All Tags")
  const [zoom, changeZoom] = useState<number>(1)
  const [currentSector, setCurrentSector] = useState<Sector | null>(null)
  const [draggingPosition, changeDraggingPosition] = useState<Coords>({ x: 0, y: 0 })
  const [draggableBounds, setDraggableBounds] = useState<DraggableBoundsType>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  })
  const [draggingState, changeDraggingState] = useState<DraggingState>({
    isDragging: false,
    timeStampStart: 0,
    timeStampEnd: 0
  })

  const triggerFilter = (filter: string) => {
    changeFiltersDisablity(state => ({ ...state, [filter]: !state[filter] }))
  }

  useEffect(() => {
    if (sectorsApiUrl) {
      initSectors(sectorsApiUrl)
    }
  }, [sectorsApiUrl])

  useEffect(() => {
    if (currentSector) {
      initCompanies(companiesApiUrl, currentSector.fields.constituents, currentSector.id)
    }
  }, [currentSector])
  useEffect(() => {
    setDraggableBounds(calcDraggableBounds())
  }, [sectors, zoom])

  useEffect(() => {
    if (draggingPosition.x < draggableBounds.left) {
      changeDraggingPosition(position => ({ ...position, x: 0 }))
    }

    if (draggingPosition.y < draggableBounds.top) {
      changeDraggingPosition(position => ({ ...position, y: 0 }))
    }
  }, [draggingPosition, draggableBounds])

  const handleDraggableStart = (e: any) => {
    e.persist()
    changeDraggingState(dragging => ({ ...dragging, isDragging: true, timeStampStart: e.timeStamp }))
  }

  const handleDraggableStop = (e: MouseEvent) => {
    changeDraggingState(dragging => ({ ...dragging, isDragging: false, timeStampEnd: e.timeStamp }))
  }

  const handleDrag = (_: any, position: DraggableData) => {
    const { x, y } = position
    changeDraggingPosition({ x, y })
  }

  const handleClickOnHex = (id: string, openContactModal: any) => {
    const searchedSector = sectors.find(sector => sector.id === id)
    const timeDifference = draggingState.timeStampEnd - draggingState.timeStampStart

    if (searchedSector && timeDifference < 350) {
      if (Boolean(searchedSector.fields.sector_completed)) {
        setCurrentSector(searchedSector)
      } else {
        openContactModal(true)
      }
    }
  }

  const handleMouseWheel = (coords: Coords) => {
    changeDraggingPosition(({ x, y }) => {
      const newX = x - coords.x
      const newY = y - coords.y
      return {
        x: newX < 0 && newX > draggableBounds.left ? newX : x,
        y: newY < 0 && newY > draggableBounds.top ? newY : y
      }
    })
  }

  const handleMoveLeft = () => {
    const newPositionX = draggingPosition.x + 100
    changeDraggingPosition(position => ({ ...position, x: newPositionX < 0 ? newPositionX : 0 }))
  }

  const handleMoveRight = () => {
    const newPositionX = draggingPosition.x - 100
    changeDraggingPosition(position => ({
      ...position,
      x: newPositionX > draggableBounds.left ? newPositionX : draggableBounds.left
    }))
  }

  return (
    <AppContextConsumer>
      {({ setContext }) => (
        <SectorMapContainer>
          {demo && (
            <Banner>
              <H2 bold align="center">
                <a href="#" onClick={() => setContext({ contactModalOpen: true })}>
                  Contact us
                </a>{" "}
                if you wish to view full content of this radar.
              </H2>
            </Banner>
          )}
          <Header onFaqOpen={() => setContext({ faqModalOpen: true })} radarName={radarName}>
            {tags && <Dropdown options={tags} onChange={changeCurrentTag} />}
            <RightNav>
              <Margin margin={{ left: "5px", right: "5px" }}>
                <NavInline>
                  <NavButton onClick={handleMoveLeft}>
                    <Icon name="arrowLeft" />
                  </NavButton>
                  <NavButton onClick={handleMoveRight}>
                    <Icon name="arrowRight" />
                  </NavButton>
                </NavInline>
              </Margin>
              <Margin margin={{ left: "5px", right: "5px" }}>
                <NavButton disabled={zoom === 1.5} onClick={() => changeZoom(1.5)}>
                  <Icon name="plus" />
                </NavButton>
              </Margin>
              <Margin margin={{ left: "5px", right: "5px" }}>
                <NavButton disabled={zoom === 1} onClick={() => changeZoom(1)}>
                  <Icon name="minus" />
                </NavButton>
              </Margin>
            </RightNav>
          </Header>

          <MapWrapper>
            <Sidebar>
              {filters.map(filter => {
                return (
                  <Filter
                    key={filter.name}
                    color={filter.color}
                    onClick={() => triggerFilter(filter.name)}
                    off={filtersState[filter.name]}
                  >
                    {filter.name}
                  </Filter>
                )
              })}
            </Sidebar>

            {Boolean(sectors.length) && (
              <MapColumn className="hex-map-container">
                <FlexInline>
                  {radarArrows && <ArrowRows radarArrows={radarArrows} draggingPosition={draggingPosition} />}
                  <div>
                    {radarArrows && <ArrowColumns radarArrows={radarArrows} draggingPosition={draggingPosition} />}
                    <DraggableBounds onBoundsChange={bounds => setDraggableBounds(bounds)}>
                      <MouseWheelScroll onPositionChange={handleMouseWheel} draggableBounds={draggableBounds}>
                        <Draggable
                          allowAnyClick
                          bounds={draggableBounds}
                          onDrag={handleDrag}
                          position={draggingPosition}
                          onStop={handleDraggableStop}
                          onStart={handleDraggableStart}
                        >
                          <div>
                            <HexMap
                              sectors={sectors}
                              currentTag={currentTag}
                              filtersState={filtersState}
                              zoom={zoom}
                              dragging={draggingState.isDragging}
                              onClick={id => handleClickOnHex(id, () => setContext({ contactModalOpen: true }))}
                              mapSize={mapSize}
                              indicatorsVisible={demo}
                            />
                          </div>
                        </Draggable>
                      </MouseWheelScroll>
                    </DraggableBounds>
                  </div>
                </FlexInline>
              </MapColumn>
            )}
          </MapWrapper>
          <Outlet
            isOpen={Boolean(currentSector)}
            onClose={() => setCurrentSector(null)}
            renderContent={({ onClose }) => (
              <Loading
                render={
                  <Modal title="SECTOR OVERVIEW" onClose={onClose}>
                    {currentSector && (
                      <Overview sector={currentSector} filters={filters} companies={companiesMap[currentSector.id]} />
                    )}
                  </Modal>
                }
                renderLoading={<LoadingSpinner color="white" />}
                isLoading={!companiesMap[currentSector!.id]}
              />
            )}
          />
          <NavigationTipModal />
        </SectorMapContainer>
      )}
    </AppContextConsumer>
  )
}

export const SectorsMap = connect(
  (state: RootState) => ({
    sectors: getSelectorsState(state),
    filters: selectors.getFilters(state),
    config: selectors.getRadarsConfig(state),
    companiesMap: getSortedCompanies(state),
    radar: selectors.getCurrentRadar(state),
    loading: selectors.isRadarLoding(state)
  }),
  { initSectors: initSectorsAction, initCompanies: initCompaniesAction }
)(withLoading(SectorsMapPlain))
