import * as React from "react"
import { Rows, Row, ArrowHead } from "./Arrows.styles"
import { Caption } from "../styles/typography"
import { RenderHTML } from "./RenderHTML"
import { Icon } from "../styles/common"

interface Props {
  draggingPosition: Coords
  radarArrows: RadarArrows
}

export const ArrowRows: React.FC<Props> = ({ draggingPosition, radarArrows }) => {
  return (
    <Rows style={{ transform: `translateY(${draggingPosition.y}px)` }} twoColumns={radarArrows.rows.length === 2}>
      <ArrowHead>
        <Icon name="arrowhead" />
      </ArrowHead>
      {radarArrows.rows.map((row, index) => (
        <Row key={index} height={`${row.size}%`}>
          <Caption bold>
            <RenderHTML html={row.name} />
          </Caption>
        </Row>
      ))}
    </Rows>
  )
}
