import { Store, compose, createStore, Dispatch, applyMiddleware } from "redux"
import { install, combineReducers } from "redux-loop"
import { connectRouter, routerMiddleware, RouterState } from "connected-react-router"
import { createBrowserHistory } from "history"

import * as firebase from "../services/firebase"
import { reducer as sectorsReducer } from "./sectors/reducer"
import { reducer as companiesReducer } from "./companies/reducer"
import { actions as userActions, reducer as userReducer } from "./user/reducer"

import { actions as appActions, reducer as appReducer } from "./app/reducer"
import { initialState } from "./initialState"
import { getCurrentRadarId, isAuthPage } from "./app/selectors"
import { User } from "firebase"
import { getOrganizationNameFromEmail, normalizeEmial } from "../utils"
import { navigate, redirect } from "../routers"

export interface RootState {
  sectors: SectorsState
  app: AppState
  router: RouterState
  companies: CompaniesState
  user: UserState
}

export type MapState<TS, TO = any> = (state: RootState, props: TO) => TS
export type MapDispatch<TA, TO = any> = (dispatch: Dispatch<any>, props: TO) => TA
export type TStore = Store<RootState>

let _history: ReturnType<typeof createBrowserHistory> = null as any
export const getHistory = () => {
  if (!_history) _history = createBrowserHistory()
  return _history
}

let _store: TStore | null = null
const initStore = () => {
  const { __REDUX_DEVTOOLS_EXTENSION__ = () => (f: any) => f } = window as any
  const store = createStore(
    combineReducers<any>({
      sectors: sectorsReducer,
      companies: companiesReducer,
      app: appReducer,
      user: userReducer,
      router: connectRouter(getHistory())
    }),
    initialState as any,
    compose(
      install(),
      __REDUX_DEVTOOLS_EXTENSION__(),
      applyMiddleware(routerMiddleware(getHistory()))
    )
  ) as TStore

  const radarId = getCurrentRadarId(store.getState())
  initFirebase(radarId)

  return store
}

export const getStore = () => {
  if (!_store) {
    _store = initStore()
  }
  return _store
}

const subscribeDataForAnonymous = (radarId: string) => {
  firebase.subscribe<RadarBasic>(`radars/${radarId}/basic`, {
    onInit: radar => {
      if (!radar) {
        redirect("/404.html")
        return
      }
      if (_store) {
        _store.dispatch(appActions.initRadarBasic(radar))

        if (radar && radar.restrictedTo === "anonymous") {
          firebase.subscribe<RadarDetails>(`radars/${radarId}/details`, {
            onInit: details => {
              _store!.dispatch(appActions.initRadarDetails(details))
            }
          })
        } else if (!isAuthPage(_store.getState())) {
          navigate(`/radar/${radar.id}/signin`)
        }
      }
    }
  })
}

const subscribeDataForUser = (radarId: string, user: User, dbUser: UserState) => {
  firebase.subscribe<RadarBasic>(`radars/${radarId}/basic`, {
    onInit: radar => {
      if (!radar) {
        redirect("/404.html")
        return
      }
      if (_store) {
        _store.dispatch(appActions.initRadarBasic(radar))
        if (
          (user.email && radar.organization === getOrganizationNameFromEmail(user.email) && user.emailVerified) ||
          radar.restrictedTo === "anonymous" ||
          dbUser.isAdmin
        ) {
          firebase.subscribe<RadarDetails>(`radars/${radarId}/details`, {
            onInit: details => {
              _store!.dispatch(appActions.initRadarDetails(details))
            }
          })
        } else if (!isAuthPage(_store.getState())) {
          navigate(`/radar/${radar.id}/signin`)
        }
      }
    }
  })
}

export const initFirebase = async (radarId?: string) => {
  const { auth } = firebase.getFirebaseDb()
  auth.onAuthStateChanged(user => {
    if (radarId) {
      if (!user) {
        subscribeDataForAnonymous(radarId)
      } else {
        firebase.subscribe<UserState>(`users/${normalizeEmial(user.email!)}`, {
          onInit: dbUser => {
            _store!.dispatch(userActions.initUserData(dbUser))
            subscribeDataForUser(radarId, user, dbUser)
          },
          onChange: (type, value) => {
            if (type === "value") {
              _store!.dispatch(userActions.updateUserData(value))
            }
          }
        })
      }
    }
  })

  firebase.subscribe<DBRadarsConfig>("config", {
    onInit: dbData => {
      if (_store) {
        _store.dispatch(appActions.initRadarConfig(dbData))
      }
    }
  })
}
